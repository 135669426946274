import {
  Home,
  Users,
  Globe,
  TableOfContents,
  BookCheck,
  MailCheck,
  Contact,
  Images,
  BedDouble,
  Layers,
  Sofa,
  Utensils,
  Footprints,
  Wifi,
  BadgePercent,
  School,
  GalleryHorizontal,
  MonitorCog,
} from "lucide-react"

import { SidebarNavItem } from "@/types/nav"

export const navigation: SidebarNavItem[] = [
  {
    title: "Dashboard",
    href: "/app",
    icon: Home,
    section: "main",
    path: ["/app"],
    allowedPermissions: ["dashboard.all", "dashboard.view"],
    items: [
      {
        title: "Overview",
        href: "/app",
        section: "dashboard",
        allowedPermissions: ["dashboard.all", "dashboard.view"],
        path: ["/app"],
        items: [],
      },
    ],
  },
  {
    title: "User Management",
    href: "/app/user-management",
    icon: Users,
    section: "settings",
    path: [
      "/app/user-management",
      "/app/user-management/roles",
      "/app/user-management/roles/edit",
      "/app/user-management/permissions",
    ],
    allowedPermissions: [
      "permission.all",
      "permission.view",
      "role.all",
      "role.view",
      "user.all",
      "user.view",
    ],
    items: [
      {
        title: "Users",
        href: "/app/user-management",
        section: "user management",
        path: ["/app/user-management"],
        allowedPermissions: ["user.all", "user.view"],
        items: [],
      },
      {
        title: "Roles",
        href: "/app/user-management/roles",
        section: "user management",
        path: ["/app/user-management/roles", "/app/user-management/roles/edit"],
        allowedPermissions: ["role.all", "role.view"],
        items: [],
      },
      {
        title: "Permissions",
        href: "/app/user-management/permissions",
        section: "user management",
        path: ["/app/user-management/permissions"],
        allowedPermissions: ["permission.all", "permission.view"],
        items: [],
      },
    ],
  },
  {
    title: "Site Management",
    href: "/app/site-management",
    icon: Globe,
    section: "settings",
    path: ["/app/site-management"],
    allowedPermissions: ["site.all", "site.view"],
    items: [
      {
        title: "Sites",
        href: "/app/site-management",
        section: "site management",
        path: ["/app/site-management"],
        allowedPermissions: ["site.all", "site.view"],
        items: [],
      },
    ],
  },
  {
    title: "Config System",
    href: "/app/config-system",
    icon: MonitorCog,
    section: "settings",
    path: ["/app/config-system"],
    allowedPermissions: ["config_system.all", "config_system.view"],
    items: [
      {
        title: "Config",
        href: "/app/config-system",
        section: "config system",
        path: ["/app/config-system"],
        allowedPermissions: ["config_system.all", "config_system.view"],
        items: [],
      },
    ],
  },
]

export const MenuIcon = ({ code }: { code: string }) => {
  switch (code) {
    case "menu":
      return <Utensils />
    case "menu.category":
      return <Layers />
    case "site_content":
      return <TableOfContents />
    case "reservation":
      return <BookCheck />
    case "newsletter_subs":
      return <MailCheck />
    case "contact":
      return <Contact />
    case "home_slider":
      return <GalleryHorizontal />
    case "room":
      return <BedDouble />
    case "room.type":
      return <Layers />
    case "room.amenity":
      return <Wifi />
    case "activity":
      return <Footprints />
    case "facility":
      return <Sofa />
    case "offer":
      return <BadgePercent />
    case "villa":
      return <School />
    case "gallery":
      return <Images />
    default:
      return null
  }
}

export const MenuLink = ({ code }: { code: string }) => {
  switch (code) {
    case "menu":
      return "/app/menu"
    case "menu.category":
      return "/app/category-menu"
    case "site_content":
      return "/app/site-content"
    case "reservation":
      return "/app/reservation"
    case "newsletter_subs":
      return "/app/subscription"
    case "contact":
      return "/app/contact"
    case "home_slider":
      return "/app/home-slider"
    case "room":
      return "/app/room"
    case "room.type":
      return "/app/room-type"
    case "room.amenity":
      return "/app/amenity"
    case "activity":
      return "/app/activity"
    case "facility":
      return "/app/facility"
    case "offer":
      return "/app/offer"
    case "villa":
      return "/app/villa"
    case "gallery":
      return "/app/gallery"
    default:
      return "/app"
  }
}

export const MenuAllowedPermissions: Record<string, string[]> = {
  "room.type": ["module.room.type.all", "module.room.type.view"],
  room: ["module.room.all", "module.room.view"],
  "room.amenity": ["module.room.amenity.all", "module.room.amenity.view"],
  activity: ["module.activity.all", "module.activity.view"],
  facility: ["module.facility.all", "module.facility.view"],
  offer: ["module.offer.all", "module.offer.view"],
  villa: ["module.villa.all", "module.villa.view"],
  gallery: ["module.gallery.all", "module.gallery.view"],
  home_slider: ["module.home_slider.all", "module.home_slider.view"],
  site_content: [
    "module.site_content.all",
    "module.site_content.view",
    "module.site_content.update",
    "module.content.all",
    "module.content.view",
    "module.content.update",
  ],
  newsletter_subs: [
    "module.newsletter_subs.all",
    "module.newsletter_subs.view",
  ],
  reservation: ["module.reservation.all", "module.reservation.view"],
  menu: ["module.menu.all", "module.menu.view"],
  "menu.category": ["module.menu.category.all", "module.menu.category.view"],
  contact: ["module.contact.all", "module.contact.view"],
}
